<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        A student ran a TLC of a mixture of two unknown compounds, A and B. Calculate the
        <stemble-latex content="$\text{R}_\text{f}$" /> values for the components of the mixture.
        Round each answer to one significant figure.
      </p>

      <p class="mb-3">
        <v-img style="max-width: 300px" src="/img/assignments/ChemUCI1LDQ2S1Q4_img1.png" />
      </p>

      <p class="mb-3">
        <calculation-input
          v-model="inputs.input1"
          prepend-text="$\text{R}_\text{f}\,\text{ of compound A}$:"
          :disabled="!allowEditing"
        />
      </p>

      <p class="mb-5">
        <calculation-input
          v-model="inputs.input2"
          prepend-text="$\text{R}_\text{f}\,\text{ of compound B}$:"
          :disabled="!allowEditing"
        />
      </p>

      <p class="mb-2">Which compound is more polar?</p>

      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing" class="mb-3">
        <v-radio
          v-for="option in options1"
          :key="'pt-2-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">Which compound is the most attracted to the eluent?</p>
      <v-radio-group v-model="inputs.input4" :disabled="!allowEditing" class="mb-3">
        <v-radio
          v-for="option in options2"
          :key="'pt-2-' + option.value"
          class="my-1 ml-3"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput.vue';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemUCI1LDQ2S1Q4',
  components: {
    StembleLatex,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
      },
      options1: [
        {text: 'Compound A', value: 'a'},
        {text: 'Compound B', value: 'b'},
        {text: 'Polarity cannot be determined from the image', value: 'indeterminable'},
      ],
      options2: [
        {text: 'Compound A', value: 'a'},
        {text: 'Compound B', value: 'b'},
        {text: 'Neither', value: 'neither'},
        {text: 'Both', value: 'both'},
      ],
    };
  },
};
</script>
<style scoped></style>
